import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";

const LocationPage = () => {
	return (
		<main>
			<div className="alt-page location-page" id="Location">
				<NavbarWeb></NavbarWeb>
				<div className="mobileNavContainer">
					<div className="navLogo">
						<StaticImage
							src="../images/Kyoto-Brush-White.png"
							placeholder="blurred"
							width={346}
							height={720}
							formats={["auto", "webp", "avif"]}
							alt="Kyoto Garden Logo"
							transformOptions={{ fit: "cover", cropFocus: "attention" }}
						/>
					</div>
					<MobileNav></MobileNav>
				</div>
				<header className="hero-banner">
					<h1>Location</h1>
					<div className="image-container">
						<StaticImage
							src="../images/Location-dark.jpg"
							placeholder="tracedSVG"
							width={1250}
							height={400}
							formats={["auto", "webp", "avif"]}
							alt="Koshi sushi chef"
							transformOptions={{ fit: "cover", cropFocus: "attention" }}
						/>
					</div>
				</header>
				<section className="section2">
					<div className="large-text">Find Kyoto Garden here:</div>
				</section>
				<a
					className="location-link2"
					href="https://www.google.com/maps/place/11+Kloof+Nek+Rd,+Gardens,+Cape+Town,+8001,+South+Africa/@-33.930069,18.408371,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677240aaee31:0xcdf4abaf5f27e068!8m2!3d-33.930069!4d18.408371"
					target="_blank"
					rel="noreferrer"
				>
					11 Kloof Nek Rd, Gardens, Cape Town, 8001, South Africa
				</a>
				<a
					className="location-link"
					href="https://www.google.com/maps/place/11+Kloof+Nek+Rd,+Gardens,+Cape+Town,+8001,+South+Africa/@-33.930069,18.408371,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677240aaee31:0xcdf4abaf5f27e068!8m2!3d-33.930069!4d18.408371"
					target="_blank"
					rel="noreferrer"
				>
					<div className="image-container">
						<StaticImage
							src="../images/map.png"
							placeholder="tracedSVG"
							width={640}
							height={300}
							formats={["auto", "webp", "avif"]}
							alt="Koshi sushi chef"
							transformOptions={{ fit: "cover", cropFocus: "attention" }}
						/>
					</div>
				</a>
				<a
					className="location-link2"
					href="https://www.google.com/maps/place/11+Kloof+Nek+Rd,+Gardens,+Cape+Town,+8001,+South+Africa/@-33.930069,18.408371,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677240aaee31:0xcdf4abaf5f27e068!8m2!3d-33.930069!4d18.408371"
					target="_blank"
					rel="noreferrer"
				>
					Open In Google Maps
				</a>
			</div>
			<Footer></Footer>
		</main>
	);
};

export default LocationPage;

export const Head = () => (
	<>
		<title>Location</title>
		<meta
			name="description"
			content="11 Kloof Nek Rd, Gardens, Cape Town, 8001, South Africa. Come and experience the best of Japanese and sushi cuisine in Cape Town at Kyoto Garden."
		/>
	</>
);
